/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  ButtonGroup,
  Table,
  Pagination,
  Form,
} from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';

import { useQuery, NetworkStatus } from '@apollo/client';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';

import pageTenantListQuery from '../queries/page_tenant_list_query';

const TenantList = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized] = useState(true);
  const [tenantList, setTenantList] = useState([]);
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const navigate = useNavigate();

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageTenantListQuery, { notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (pageData?.tenantList?.length) {
      setTenantList(pageData?.tenantList);
    }
    // console.log("tenents page", pageData?.tenantList);
  }, [pageData]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const handleShow = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id');
      navigate(`/tenants/${id}`);
    },
    [navigate]
  );

  const renderActionCell = useCallback(
    ({ row }) => {
      const {
        original: { adTenantId },
      } = row;
      return (
        <ButtonGroup>
          <Button
            className="me-1"
            size="sm"
            data-id={adTenantId}
            onClick={handleShow}
            // disabled={!includes(actions, NotificationActionTypes.REVIEW)}
          >
            review
          </Button>
          {/* <Confirm
            onConfirm={handleRelease}
            dataId={id}
            title="Release Message"
            body={releaseMessages(released)}
            confirmText="Confirm"
          >
            <Button
              variant="danger"
              size="sm"
              disabled={
                !(
                  includes(actions, NotificationActionTypes.REVIEW) &&
                  includes(actions, NotificationActionTypes.RELEASE)
                )
              }
            >
              release
            </Button>
          </Confirm> */}
        </ButtonGroup>
      );
    },
    [handleShow]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Actions',
        columnClassName: 'text-center',
        Cell: renderActionCell,
      },
    ],
    [renderActionCell]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tenantList,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const renderContent = () => {
    return (
      <>
        <Helmet title="Tenant List" />
        <Container fluid className="p-0">
          <Row>
            <Col sm={12}>
              <div className="float-none">
                {/* <div className="float-start">
                <h1 className="h3 mb-3">{`Tenant list for ${get(
                  account,
                  'name',
                  'user'
                )}`}</h1>
              </div> */}
                <div className="float-end my-2">
                  <Button
                    variant="primary"
                    onClick={handleRefetch}
                    disabled={pageLoading}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {tenantList.length ? (
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Table responsive striped bordered {...getTableProps()}>
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                              return (
                                <th
                                  {...(column.columnClassName && {
                                    className: column.columnClassName,
                                  })}
                                  {...column.getHeaderProps()}
                                >
                                  {column.render('Header')}
                                </th>
                              );
                            })}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...(cell.column.columnClassName && {
                                      className: cell.column.columnClassName,
                                    })}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Row>
                      <Col md="6">
                        <span className="mx-2">
                          Page{' '}
                          <strong>
                            {pageIndex + 1} of {pageOptions.length}
                          </strong>
                        </span>
                        <span className="ms-3 me-2">Show:</span>
                        <Form.Select
                          className="d-inline-block w-auto"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value));
                          }}
                        >
                          {[10, 20, 30, 40, 50].map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </Form.Select>

                        <span className="ms-3 me-2">Go to page:</span>
                        <Form.Control
                          className="d-inline-block"
                          type="number"
                          defaultValue={pageIndex + 1}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPage(pageNumber);
                          }}
                          style={{ width: '75px' }}
                        />
                      </Col>
                      <Col md="6">
                        <Pagination className="float-end">
                          <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          />
                          <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          />
                          <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          />
                          <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          />
                        </Pagination>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card>
                  <Card.Header className="pb-0">
                    <Card.Title tag="h5" className="mb-0">
                      Not found
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    We were unable to retrieve the list of tenants for review
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  };

  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {isAuthenticated && !pageError && !pageLoading}
      {isAuthenticated &&
        isAuthorized &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default TenantList;
