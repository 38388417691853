import { useState, useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMutation } from '@apollo/client';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';

import mxtoolboxCreate from '../mutations/mxtoolbox_create_mutation';
import { toastSuccess, toastError } from '../lib/toast';
import useSettings from '../hooks/use_settings';

const Mxtoolbox = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized] = useState(true);
  const { isMutating, setIsMutating } = useSettings();
  const [
    mxtoolboxCreation,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(mxtoolboxCreate);

  const [inputDomain, setInputDomain] = useState(null);
  const [mxtoolbox, setMxtoolbox] = useState(null);

  useEffect(() => {
    if (mutationData?.mxtoolboxCreate) {
      setMxtoolbox(mutationData.mxtoolboxCreate);
    }
  }, [mutationData]);

  const handleMxtoolboxCreation = async (domain) => {
    if (domain) {
      const mutationOptions = {
        variables: { input: { domain } },
      };
      try {
        setIsMutating(true);
        await mxtoolboxCreation(mutationOptions);
        toastSuccess('MX Toolbox Creation ok');
      } catch (err) {
        console.log(err.toString());
        toastError('MX Toolbox Creation failed');
      } finally {
        setIsMutating(false);
      }
    }
  };

  const handleChange = (e) => {
    setInputDomain(e.target.value);
  };

  const renderContent = () => (
    <div className="row">
      <p className="h2 fw-normal mt-3 mb-4">Domain detail lookup</p>
      <div>
        <div className="input-group mb-3 col-xs-2">
          <input
            type="text"
            className="form-control"
            placeholder="enter a domain"
            aria-label="domain"
            aria-describedby="basic-addon2"
            onChange={handleChange}
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => handleMxtoolboxCreation(inputDomain)}
              disabled={!inputDomain || mutationLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {mxtoolbox && <pre className="h6">{JSON.stringify(mxtoolbox, undefined, 2)}</pre>}
    </div>
  );
  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(isMutating)}
      {renderError(mutationError)}
      {isAuthenticated && isAuthorized && !mutationError && renderContent()}
    </div>
  );
};

export default Mxtoolbox;
