/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { Helmet } from 'react-helmet-async';

import {
  Card,
  Col,
  Container,
  Row,
  Button,
  ButtonGroup,
  Table,
  Pagination,
  Form,
  ListGroup,
  Tab,
} from 'react-bootstrap';

import { useTable, usePagination } from 'react-table';

import { useQuery, NetworkStatus } from '@apollo/client';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';

import tenantQuery from '../queries/tenant_query';

const TenantShow = () => {
  const isAuthenticated = useIsAuthenticated();
  const params = useParams();
  const navigate = useNavigate();

  const [isAuthorized] = useState(true);
  const [tenantItem, setTenantItem] = useState(null);
  const [tenantUsersList, setTenantUsersList] = useState([]);
  const [tenantGroupsList, setTenantGroupsList] = useState([]);
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(tenantQuery, {
    variables: { id: params.id },
    skip: !params.id,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (pageData?.tenant) {
      // console.log(pageData.tenant);
      setTenantItem(pageData.tenant);
      setTenantUsersList(pageData.tenant.users);
      setTenantGroupsList(pageData.tenant.groups);
    }
  }, [pageData]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const handleUserShow = useCallback(
    (e) => {
      const { id } = params;
      const userId = e.currentTarget.getAttribute('data-user-id');
      navigate(`/tenants/${id}/users/${userId}`);
    },
    [navigate, params]
  );

  const renderActionCellUser = useCallback(
    ({ row }) => {
      const {
        original: { tenantId, adUserId },
      } = row;
      return (
        <ButtonGroup>
          <Button
            className="me-1"
            size="sm"
            data-id={tenantId}
            data-user-id={adUserId}
            onClick={handleUserShow}
            // disabled={!includes(actions, NotificationActionTypes.REVIEW)}
          >
            review
          </Button>
        </ButtonGroup>
      );
    },
    [handleUserShow]
  );

  const UsersColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'displayName',
      },
      {
        Header: 'Actions',
        columnClassName: 'text-center',
        Cell: renderActionCellUser,
      },
    ],
    [renderActionCellUser]
  );

  const {
    getTableProps: getTablePropsUsers,
    getTableBodyProps: getTableBodyPropsUsers,
    headerGroups: headerGroupsUsers,
    prepareRow: prepareRowUsers,
    page: pageUsers,
    canPreviousPage: canPreviousPageUsers,
    canNextPage: canNextPageUsers,
    pageOptions: pageOptionsUsers,
    pageCount: pageCountUsers,
    gotoPage: gotoPageUsers,
    nextPage: nextPageUsers,
    previousPage: previousPageUsers,
    setPageSize: setPageSizeUsers,
    state: { pageIndex: pageIndexUsers, pageSize: pageSizeUsers },
  } = useTable(
    {
      columns: UsersColumns,
      data: tenantUsersList,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const handleGroupShow = useCallback(
    (e) => {
      const { id } = params;
      const groupId = e.currentTarget.getAttribute('data-group-id');
      navigate(`/tenants/${id}/groups/${groupId}`);
    },
    [navigate, params]
  );

  const renderActionCellGroup = useCallback(
    ({ row }) => {
      const {
        original: { tenantId, adGroupId },
      } = row;
      return (
        <ButtonGroup>
          <Button
            className="me-1"
            size="sm"
            data-id={tenantId}
            data-group-id={adGroupId}
            onClick={handleGroupShow}
            // disabled={!includes(actions, NotificationActionTypes.REVIEW)}
          >
            review
          </Button>
        </ButtonGroup>
      );
    },
    [handleGroupShow]
  );

  const groupsColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'displayName',
      },
      {
        Header: 'Actions',
        columnClassName: 'text-center',
        Cell: renderActionCellGroup,
      },
    ],
    [renderActionCellGroup]
  );

  const {
    getTableProps: getTablePropsGroups,
    getTableBodyProps: getTableBodyPropsGroups,
    headerGroups: headerGroupsGroups,
    prepareRow: prepareRowGroups,
    page: pageGroups,
    canPreviousPage: canPreviousPageGroups,
    canNextPage: canNextPageGroups,
    pageOptions: pageOptionsGroups,
    pageCount: pageCountGroups,
    gotoPage: gotoPageGroups,
    nextPage: nextPageGroups,
    previousPage: previousPageGroups,
    setPageSize: setPageSizeGroups,
    state: { pageIndex: pageIndexGroups, pageSize: pageSizeGroups },
  } = useTable(
    {
      columns: groupsColumns,
      data: tenantGroupsList,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const renderContent = () => {
    const { id, name, users, groups } = tenantItem;
    return (
      <>
        <p className="h2 fw-normal mt-3 mb-4">Tenant Item</p>
        <Helmet title="Tenant Item" />
        <Container fluid className="p-0">
          {id ? (
            <>
              <Row>
                <Col sm={12}>
                  <div className="float-none">
                    {/* <div className="float-start">
                    <h1 className="h3 mb-3">{`Tenant list for ${get(account,'name','user')}`}</h1>
                  </div> */}
                    <div className="float-end my-2">
                      <Button
                        variant="primary"
                        onClick={handleRefetch}
                        disabled={pageLoading}
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
                      <Tab.Content>
                        <Tab.Pane eventKey="basic">
                          <ListGroup variant="flush">
                            <ListGroup.Item variant="info">
                              <span style={{ display: 'inline-block', width: '100px' }}>
                                Tenant info:
                              </span>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <span style={{ display: 'inline-block', width: '100px' }}>
                                ID:
                              </span>
                              {id}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <span style={{ display: 'inline-block', width: '100px' }}>
                                Name:
                              </span>
                              {name}
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col>
                <Card>
                  <Card.Header className="pb-0">
                    <Card.Title tag="h5" className="mb-0">
                      Not found
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    We were unable to retrieve the tenant for review
                    <ul>
                      <li>It may have expired</li>
                      <li>This tenant id may not relate to your id</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {id && users && users.length > 0 ? (
            <Row>
              <Col>
                <p className="h2 fw-normal mt-3 mb-4">Tenant Item Users</p>
                <Card>
                  <Card.Body>
                    <Table responsive striped bordered {...getTablePropsUsers()}>
                      <thead>
                        {headerGroupsUsers.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                              return (
                                <th
                                  {...(column.columnClassName && {
                                    className: column.columnClassName,
                                  })}
                                  {...column.getHeaderProps()}
                                >
                                  {column.render('Header')}
                                </th>
                              );
                            })}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyPropsUsers()}>
                        {pageUsers.map((row) => {
                          prepareRowUsers(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...(cell.column.columnClassName && {
                                      className: cell.column.columnClassName,
                                    })}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Row>
                      <Col md="6">
                        <span className="mx-2">
                          Page{' '}
                          <strong>
                            {pageIndexUsers + 1} of {pageOptionsUsers.length}
                          </strong>
                        </span>
                        <span className="ms-3 me-2">Show:</span>
                        <Form.Select
                          className="d-inline-block w-auto"
                          value={pageSizeUsers}
                          onChange={(e) => {
                            setPageSizeUsers(Number(e.target.value));
                          }}
                        >
                          {[10, 20, 30, 40, 50].map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </Form.Select>

                        <span className="ms-3 me-2">Go to page:</span>
                        <Form.Control
                          className="d-inline-block"
                          type="number"
                          defaultValue={pageIndexUsers + 1}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPageUsers(pageNumber);
                          }}
                          style={{ width: '75px' }}
                        />
                      </Col>
                      <Col md="6">
                        <Pagination className="float-end">
                          <Pagination.First
                            onClick={() => gotoPageUsers(0)}
                            disabled={!canPreviousPageUsers}
                          />
                          <Pagination.Prev
                            onClick={() => previousPageUsers()}
                            disabled={!canPreviousPageUsers}
                          />
                          <Pagination.Next
                            onClick={() => nextPageUsers()}
                            disabled={!canNextPageUsers}
                          />
                          <Pagination.Last
                            onClick={() => gotoPageUsers(pageCountUsers - 1)}
                            disabled={!canNextPageUsers}
                          />
                        </Pagination>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card>
                  <Card.Header className="pb-0">
                    <Card.Title tag="h5" className="mb-0">
                      Not found
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    We were unable to retrieve the tenant users table
                    <ul>
                      <li>It may have expired</li>
                      <li>This tenant id may not relate to your id</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {id && groups && groups.length > 0 ? (
            <Row>
              <Col>
                <p className="h2 fw-normal mt-3 mb-4">Tenant Item Groups</p>
                <Card>
                  <Card.Body>
                    <Table responsive striped bordered {...getTablePropsGroups()}>
                      <thead>
                        {headerGroupsGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                              return (
                                <th
                                  {...(column.columnClassName && {
                                    className: column.columnClassName,
                                  })}
                                  {...column.getHeaderProps()}
                                >
                                  {column.render('Header')}
                                </th>
                              );
                            })}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyPropsGroups()}>
                        {pageGroups.map((row) => {
                          prepareRowGroups(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...(cell.column.columnClassName && {
                                      className: cell.column.columnClassName,
                                    })}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Row>
                      <Col md="6">
                        <span className="mx-2">
                          Page{' '}
                          <strong>
                            {pageIndexGroups + 1} of {pageOptionsGroups.length}
                          </strong>
                        </span>
                        <span className="ms-3 me-2">Show:</span>
                        <Form.Select
                          className="d-inline-block w-auto"
                          value={pageSizeGroups}
                          onChange={(e) => {
                            setPageSizeGroups(Number(e.target.value));
                          }}
                        >
                          {[10, 20, 30, 40, 50].map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </Form.Select>

                        <span className="ms-3 me-2">Go to page:</span>
                        <Form.Control
                          className="d-inline-block"
                          type="number"
                          defaultValue={pageIndexGroups + 1}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPageGroups(pageNumber);
                          }}
                          style={{ width: '75px' }}
                        />
                      </Col>
                      <Col md="6">
                        <Pagination className="float-end">
                          <Pagination.First
                            onClick={() => gotoPageGroups(0)}
                            disabled={!canPreviousPageGroups}
                          />
                          <Pagination.Prev
                            onClick={() => previousPageGroups()}
                            disabled={!canPreviousPageGroups}
                          />
                          <Pagination.Next
                            onClick={() => nextPageGroups()}
                            disabled={!canNextPageGroups}
                          />
                          <Pagination.Last
                            onClick={() => gotoPageGroups(pageCountGroups - 1)}
                            disabled={!canNextPageGroups}
                          />
                        </Pagination>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card>
                  <Card.Header className="pb-0">
                    <Card.Title tag="h5" className="mb-0">
                      Not found
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    We were unable to retrieve the tenant groups table
                    <ul>
                      <li>It may have expired</li>
                      <li>This tenant id may not relate to your id</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  };
  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {isAuthenticated && !pageError && !pageLoading}
      {isAuthenticated &&
        isAuthorized &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default TenantShow;
