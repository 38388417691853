import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import LandingLayout from '../layouts/landing_layout';
import DashboardLayout from '../layouts/dashboard_layout';
import AuthenticatedDashboardLayout from '../layouts/authenticated_dashboard_layout';

import Landing from '../pages/landing';
import Home from '../pages/home';
import Profile from '../pages/profile';
import Function from '../pages/function';
import Page404 from '../pages/page_404';
import Graphql from '../pages/graphql';
import CheckableMessage from '../pages/checkable_message';
import Message from '../pages/message';
import TenantList from '../pages/tenant_list';
import TenantShow from '../pages/tenant_show';
import TenantGroupShow from '../pages/tenant_group_show';
import TenantUserShow from '../pages/tenant_user_show';
import EmailFooter from '../pages/email_footer';
import Mxtoolbox from '../pages/mxtoolbox_show';

const AppRoutes = () =>
  useRoutes([
    {
      path: '/',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: (
            <>
              <UnauthenticatedTemplate>
                <LandingLayout>
                  <Landing />
                </LandingLayout>
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <DashboardLayout>
                  <Home />
                </DashboardLayout>
              </AuthenticatedTemplate>
            </>
          ),
        },
      ],
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    // {
    //   path: '/search',
    //   element: <AuthenticatedDashboardLayout />,
    //   children: [
    //     {
    //       path: 'service',
    //       children: [
    //         {
    //           path: ':id',
    //           element: <ServiceShow />,
    //         },
    //         {
    //           path: '',
    //           element: <ServiceList />,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      path: '/search',
      element: <AuthenticatedDashboardLayout />,
      children: [
        {
          path: 'checkable-message',
          element: <CheckableMessage />,
        },
        {
          path: 'message',
          element: <Message />,
        },
      ],
    },
    {
      path: '/develop',
      element: <AuthenticatedDashboardLayout />,
      children: [
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: 'function',
          element: <Function />,
        },
        {
          path: 'graphql',
          element: <Graphql />,
        },
      ],
    },
    {
      path: '/tenants',
      element: <AuthenticatedDashboardLayout />,
      children: [
        {
          path: '',
          element: <TenantList />,
        },
        {
          path: ':id',
          children: [
            {
              path: '',
              element: <TenantShow />,
            },
            {
              path: 'users/:userId',
              element: <TenantUserShow />,
            },
            {
              path: 'groups/:groupId',
              element: <TenantGroupShow />,
            },
          ],
        },
      ],
    },
    {
      path: '/mxtoolbox',
      element: <AuthenticatedDashboardLayout />,
      children: [
        {
          path: '',
          element: <Mxtoolbox />,
        },
      ],
    },
    {
      path: '/emailfooter',
      element: <AuthenticatedDashboardLayout />,
      children: [
        {
          path: '',
          element: <EmailFooter />,
        },
      ],
    },
    {
      path: '/*',
      element: <Navigate to="/404" />,
    },
  ]);

export default AppRoutes;
